import React, { useEffect, useState } from 'react';

import { useAsyncFn } from 'react-use';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';

import { LbModal } from '../../../shared/components/modals';
import { LbInput } from '../../../shared/components/form-components';
import { pinCodeService } from '../../services/pinCodeService';
import { getRouteParam } from '../../../shared/utils/angularUtils';
import { patterns } from '../../../shared/constants/patterns';

type SetUpPinCodeModalProps = {
  onClose: () => void;
};

type SetUpPinCodeFormValue = {
  pinCode: number;
};

const SetUpPinCodeModal = ({ onClose }: SetUpPinCodeModalProps) => {
  const { control, handleSubmit } = useForm<SetUpPinCodeFormValue>();
  const [hasPinCode, setHasPinCode] = useState<boolean>(false);

  useEffect(() => {
    getCoachPinCode();
  }, []);

  const getCoachPinCode = async () => {
    const resp = await pinCodeService.getCoachPinCode(coachId);

    setHasPinCode(Boolean(resp?.pincode));
  };

  const handlerPasswordChange = handleSubmit(async (data) => {
    await pinCodeService.saveCoachPinCode({
      coachId,
      hasPinCode,
      newPinCode: data.pinCode,
    });
    enqueueSnackbar({
      message: 'PIN code has been updated successfully',
      variant: 'success',
    });
    onClose();
  });
  const [status, handleFormSubmit] = useAsyncFn(handlerPasswordChange, [hasPinCode]);

  const coachId = getRouteParam('id');

  return (
    <LbModal
      open
      title="Set up PIN Code"
      text="Enter the 4-digit code"
      primaryBtnConfig={{ text: 'Save', onClick: handleFormSubmit }}
      secondaryBtnConfig={{ text: 'Cancel', onClick: onClose }}
      isLoading={status.loading}
    >
      <form noValidate>
        <LbInput
          control={control}
          size="small"
          variant="outlined"
          label="PIN Code"
          name="pinCode"
          rules={{
            required: 'PIN code is required',
            pattern: {
              value: patterns.FOUR_DIGIT_NUMBER,
              message: 'The pin code is a 4-digit code',
            },
          }}
        />
      </form>
    </LbModal>
  );
};

export { SetUpPinCodeModal };

import React, { useState } from 'react';

import { angularize } from 'react-in-angularjs';
import { Button } from '@mui/material';
import { observer } from 'mobx-react';

import { SystemProviders } from '../../../shared/providers';
import classes from './NgzPasswordTab.module.scss';
import { getCurrentUrl } from '../../../shared/utils/angularUtils';
import { SetUpPinCodeModal } from '../../components/SetUpPinCodeModal/SetUpPinCodeModal';
import { InfoBox } from '../../../shared/components/ui-components';
import { CoachPasswordBlock } from '../../components/CoachPasswordBlock/CoachPasswordBlock';

type NgzPasswordTabProps = {
  userId: number;
  userEmail: string;
};

const NgzPasswordTab = observer(({ userId, userEmail }: NgzPasswordTabProps) => {
  const [isShowPinCodeModal, setIsShowPinCodeModal] = useState(false);

  const isProfile = getCurrentUrl().includes('profile');

  return (
    <SystemProviders>
      {userId && userEmail && (
        <CoachPasswordBlock userId={userId} userEmail={userEmail} />
      )}
      {!isProfile && (
        <InfoBox title="Pin Code">
          <div className={classes.row}>
            <div className={classes.rowLeft}>
              <p className={classes.title}>Set up a PIN code as a quick access</p>
              <p className={classes.description}>
                Set a PIN code, only for corporate accounts
              </p>
            </div>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={() => setIsShowPinCodeModal(true)}
            >
              Set up Pin Code
            </Button>
          </div>
        </InfoBox>
      )}
      {isShowPinCodeModal && (
        <SetUpPinCodeModal onClose={() => setIsShowPinCodeModal(false)} />
      )}
    </SystemProviders>
  );
});

angularize(NgzPasswordTab, 'ngzPasswordTab', angular.module('app.authManagement'), {
  userId: '<',
  userEmail: '<',
});
